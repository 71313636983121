import React from 'react';
import { graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import BlogPostSnippet from '../components/blog-post-snippet';
import HeaderImage from '../components/header-image';

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata?.title || `Title`;
	const posts = data.allMarkdownRemark.nodes;

	return (
    <Layout location={location} title={siteTitle}>
    <HeaderImage/>
			<SEO title="All posts" />
			{/* <Bio /> */}
			<ol style={{ listStyle: `none` }}>
				{/* {posts.map(post => (
					<BlogPostSnippet post={post} />
				))} */}
			</ol>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					description
				}
			}
		}
	}
`;
