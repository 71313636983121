import React from 'react';

import hillImg from '../../../content/assets/hill.png';
import houseImg from '../../../content/assets/house.png';
import sunImg from '../../../content/assets/sun.png';
import birdImg1 from '../../../content/assets/birds1.png';
import birdImg2 from '../../../content/assets/birds2.png';

const HeaderImage = () => {
	return (
		<section id="home-slider">
			<div class="container">
				<div class="row">
					<div class="main-slider animate-in">
						<div class="slide-text">
							<h1>I'm busy making something</h1>
							<p>
								This site has been more empty than my piggybank for a while now
								so I've decided to put a placeholder.
								<br />
								If you need me, reach out on Linkedin
							</p>
							<a
								href="https://www.linkedin.com/in/jacob-sidford/"
								class="btn btn-common"
							>
								Linkedin
							</a>
						</div>
						<img src={hillImg} class="hero-img slider-hill" alt="" />
						<img src={houseImg} class="hero-img slider-house" alt="" />
						<img src={sunImg} class="hero-img slider-sun" alt="" />
						<img src={birdImg1} class="hero-img slider-birds1" alt="" />
						<img src={birdImg2} class="hero-img slider-birds2" alt="" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeaderImage;
